import React from 'react'
import Helmet from 'react-helmet'
import { graphql, PageProps } from 'gatsby'

import Layout from '../../layouts'
import HeaderText from '../../components/HeaderText'
import HeaderWrapper from '../../components/HeaderWrapper'
import IconLink from '../../components/IconLink'

import javaIcon from './icons/java.svg'
import pythonIcon from './icons/python.svg'
import jsIcon from './icons/javascript.svg'
import cppIcon from './icons/cplusplus.svg'
import reactIcon from './icons/react.svg'
import gatsbyIcon from './icons/gatsby.svg'

interface ProjectPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
      }
    }
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

const ProjectPage: React.FC<ProjectPageProps> = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>{`Projects | ${data.site.siteMetadata.title}`}</title>
      </Helmet>
      <HeaderWrapper>
        <HeaderText>{'Coding'}</HeaderText>
        <h2>{'I like to code. You can find more on my GitHub.'}</h2>
      </HeaderWrapper>
      <div style={{ maxWidth: '800px', margin: '20px auto 140px' }}>
        <p>
          {"In the past, I've used "}
          <IconLink
            icon={javaIcon}
            text="Java,"
            to={'https://www.java.com'}
          />{' '}
          <IconLink
            icon={pythonIcon}
            text="Python,"
            to={'https://www.python.org'}
          />{' '}
          <IconLink
            icon={jsIcon}
            text="JavaScript,"
            to={'https://www.javascript.com'}
          />
          {' and '}
          <IconLink
            icon={cppIcon}
            text="C++."
            to={'http://www.cplusplus.com'}
          />
          <br />
          {'This blog was written using '}
          <IconLink icon={reactIcon} text="React " to={'https://reactjs.org'} />
          {'and '}
          <IconLink
            icon={gatsbyIcon}
            text="Gatsby."
            to={'https://www.gatsbyjs.org'}
          />
        </p>
      </div>
    </Layout>
  )
}

export default ProjectPage
